@import './reset';

:root {
  --font-inverted: #ffffff;
}

.site-layout {
  min-height: 100vh;

  &--content {
    height: 100%;
    padding: 24px;
    background: var(--font-inverted);
  }
}

.site-sidebar {
  .user {
    margin: 16px;
    color: var(--font-inverted);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

  .login-form {
    width: 400px;
    margin: auto;
  }

  .login-form-button {
    width: 100%;
  }

  .login-form-forgot {
    display: block;
    text-align: center;
  }
}

.loading-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    font-size: 40px;
    color: #1890ff;
  }
}

.sound-player-column {
  vertical-align: middle;
}

.search-input {
  width: 250px;
  margin-bottom: 14px;
  margin-right: 14px;
}

.search-operator {
  margin-bottom: 14px;

  & > span {
    margin-right: 14px;
  }
}

.truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
