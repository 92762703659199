.search-container {
  display: flex;
  gap: 10px;
}

.search-input {
  flex: 1;
  max-width: 200px;
}

.search-btn {
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
