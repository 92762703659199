.thumbWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 750px;
  overflow: auto;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #eeeeee;
  border: 1px solid #dddddd;
}

.thumb {
  width: 170px;
  margin: 15px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background-color: white;

  &:hover {
    box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
      0 5px 12px 4px #00000017;
  }
}

.thumb:hover .thumbAction {
  visibility: visible;
}

.thumbAction {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  visibility: hidden;
}

.thumbBtn {
  margin-left: 10px;
  transition: none !important;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modalForm {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.thumbMeta {
  padding: 10px;
  & > span {
    color: #333;
    font-weight: 200;
    font-style: italic;
    font-size: 12px;
  }
}

.searchSelect {
  flex: 1 !important;
  margin-left: 20px !important;
}

.noData {
  padding-left: 20px;
  font-size: 14px;
  font-style: italic;
}
