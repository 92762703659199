.edit-metaverse-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.edit-metaverse-container .ant-input,
.edit-metaverse-container .ant-btn {
  width: 100%;
}

.edit-metaverse-container .button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.edit-metaverse-container .button-container .ant-btn {
  flex: 1;
  margin-right: -1px;
}

.edit-metaverse-container .image-preview {
  display: flex;
  margin-top: 16px;
}

.edit-metaverse-container .image-preview img {
  width: 264px;
  height: 260px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-right: -3px;
  margin-left: 0px;
}

.edit-metaverse-container .image-preview .thumb-img {
  margin-left: 3%;
}

.edit-metaverse-container .button-container .save-button {
  margin-right: 25px;
}

.inline-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.inline-form-item label {
  margin-right: 10px;
  min-width: 100px;
  font-weight: bold;
}

.inline-form-item input {
  flex: 1;
}

.image-preview img {
  max-width: 48%;
  border-radius: 8px;
  margin-left: 20px;
}
