.card-container {
  width: 200px;
  position: relative;
}

.card-cover {
  position: relative;
  overflow: hidden;
}

.card-image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.carousel-dots {
  position: absolute;
  bottom: 10px;
  width: 67%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.carousel-dots li {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.carousel-dots li.slick-active {
  background-color: #cc669f;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  background: #cc669f;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #cc669f;
}

.edit-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  font-size: 20px;
  z-index: 2;
}

.delete-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #ff1837;
  color: white;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  font-size: 20px;
  z-index: 2;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.button-container {
  display: flex;
  margin-bottom: 16px;
}

.image-preview {
  display: flex;
}

.image-preview img {
  height: 200px;
  width: 220px;
  margin-right: 16px;
}

.edit-img-button {
  width: 220px;
}

.edit-thumb-button {
  width: 220px;
  margin-left: 30px;
}

.edit-save-button {
  width: 220px;
}

.thumb-img {
  margin-left: 15px;
  width: 220px;
}

.ant-carousel .slick-dots-bottom {
  bottom: 0px;
}
