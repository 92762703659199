.monthly-metaverse-container {
  padding: 20px;
}

.inline-form-item {
  margin-bottom: 16px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.image-row {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.image-box {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-left: auto;
}
