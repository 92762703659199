.carousel {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
    p {
      margin-bottom: 5px;
    }
  }
  
  .carouselTitle {
    display: flex;
    justify-content: space-between;
  }
  
  .carouselSpace {
    
    width: 100%;
    overflow: auto;
    border: 1px solid #ddd;
    background-color: #eee;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .carouselItem {
    padding-left: 10px;
    padding-right: 10px;
    border-left-width: 2px;
    border-left-style: dashed;
    border-left-color: transparent;
    width: 150px;
    height: 200px;
  }
  
  .carouselItemCard {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 2px;
    position: relative;
  
    &:hover {
      box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
        0 5px 12px 4px #00000017;
      cursor: grab;
    }
  }
  
  .carouselItemCard:hover .carouselItemDelBtn {
    visibility: visible;
  }
  
  .carouselItemDelBtn {
    position: absolute !important;
    top: 5px;
    right: 5px;
    visibility: hidden;
    transition: none !important;
  }
  
  .carouselItemMeta {
    padding: 5px 10px;
    & > span {
      display: block;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  
  .noData {
    padding-left: 20px;
    font-size: 14px;
    font-style: italic;
  }
  
  .searchSelect {
    display: flex;
    width: 100%;
  }
  