.edit-metaverse-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.edit-metaverse-container .ant-input,
.edit-metaverse-container .ant-btn {
  width: 100%;
}

.edit-metaverse-container .button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.edit-metaverse-container .button-container .ant-btn {
  flex: 1;
  margin-right: -1px;
}

.inline-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.inline-form-item label {
  margin-right: 10px;
  min-width: 100px;
  font-weight: bold;
}

.inline-form-item input {
  flex: 1;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.image-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.delete-icon1 {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(111, 231, 213);
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  margin-top: 10px;
}

.switch-container .ant-switch {
  margin-left: 53px;
}

.add-image {
  margin-top: 20px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
