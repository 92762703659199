.card-container {
  width: 200px;
  position: relative;
}

.card-cover {
  position: relative;
  overflow: hidden;
}

.card-image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.edit-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  font-size: 20px;
  pointer-events: auto;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.button-container {
  display: flex;
  margin-bottom: 16px;
}

.image-preview {
  display: flex;
}

.image-preview img {
  height: 200px;
  width: 220px;
  margin-right: 16px;
}

.edit-img-button {
  width: 220px;
}

.edit-thumb-button {
  width: 220px;
  margin-left: 30px;
}
.edit-save-button {
  width: 220px;
}

.thumb-img {
  margin-left: 15px;
  width: 220px;
}

.card-container .ant-card-meta-description {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card-description.full {
  white-space: normal;
}

.card-container.faded-card {
  opacity: 0.5;
  pointer-events: none;
}
