.tag {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  background-color: white;
  color: black;
  padding: 4px 12px;
}

.tag.active {
  background-color: #1890ff;
  color: white;
  border: none;
}

.section {
  display: none;
}

.section.active {
  display: block;
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 10px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-button {
  margin-left: auto;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-button {
    margin-top: 10px;
  }
}
