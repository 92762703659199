.edit-games-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-games-container .ant-typography {
  text-align: center;
}

.edit-games-container .ant-divider {
  margin: 16px 0;
}

.edit-games-container .ant-input,
.edit-games-container .ant-select {
  width: 100%;
  margin-bottom: 7px;
}

.edit-games-container .ant-btn {
  width: 47%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.edit-img-button,
.edit-thumb-button,
.save-button {
  width: 48%;
}

.image-preview {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.image-preview img {
  max-width: 48%;
  border-radius: 8px;
  margin-left: 20px;
}

.thumb-img {
  margin-left: 16px;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  margin-top: 10px;
}

.switch-container .ant-switch {
  margin-left: 10px;
}
.inline-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.inline-form-item label {
  margin-right: 10px;
  min-width: 100px;
  font-weight: bold;
}

.inline-form-item input {
  flex: 1;
}

.image-preview img {
  max-width: 48%;
  border-radius: 8px;
  margin-left: 20px;
}
